import {combineReducers} from 'redux';
import {all} from 'redux-saga/effects';
import auth, {authSaga} from './auth';
import loading from './loading';
import user, {userSaga} from './user';

const rootReducer = combineReducers({
  auth,
  loading,
  user,
});

export function* rootSaga() {
  yield all([authSaga(), userSaga()]);
}

export default rootReducer;

/*
import {all} from 'redux-saga/effects';
import {combineReducers} from 'redux';
import loading from './loading';

import auth, {authSaga} from './auth';

const rootReducer = combineReducers({
  loading,
  auth,
});

export function * rootSaga() {
  yield all([authSaga()]);
}

export default rootReducer;
*/
