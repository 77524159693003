import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import LoadingContext from './Context/LoadingContext';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import rootReducer, {rootSaga} from './modules';
import createSagaMiddleware from 'redux-saga';
import Loading from './components/Loading';
import {getCookie} from './lib/Cookie';
import {check, tempSetUser} from './modules/user';
import ScrollToTop from './lib/ScrollTop';
import LoadingContextComponent from './components/LoadingContextComponent';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);


function loadUser() {
  try{
    const access_token = getCookie('access_token');
    const user = localStorage.getItem('user');
    if(!user || !access_token) return;
    store.dispatch(tempSetUser(JSON.parse(user)));
    store.dispatch(check());
  } catch (e) {}
}
loadUser();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Loading />
      <ScrollToTop />
      <App />
    </Provider>
  </BrowserRouter>
);
reportWebVitals();
