import React, {useLayoutEffect} from 'react';
import logoImg from 'img/logo.png';
import styled from "styled-components";
import {Link} from 'react-router-dom';
import MobileSide from './common/MobileSide';

const HeaderNavStd = styled.div`
  display: flex; gap: 60px; flex-wrap: wrap;
  @media screen and (max-width: 1200px) {
    gap: 4px 20px; font-size: 15px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`
const HeaderHomeLink = styled(Link)`
`
const MemberWrapStd = styled.div`
  display: flex; gap: 60px; flex-wrap: wrap;
  @media screen and (max-width: 1200px) {
    gap: 4px 20px; font-size: 15px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`
const HeaderWrapStd = styled.div`
  font-size: 16px; font-weight: 500;
  padding: 0 60px;
  height: 68px;
  display: flex; align-items: center; justify-content: space-between;
  position: relative;
  & ${HeaderHomeLink} img { height: 38px; }
  @media screen and (max-width: 1200px) {
    padding: 0 16px;
  }
`;
const MobileSideWrap = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`
const MobileButton = styled.div`
  display: flex; flex-direction: column; gap: 6px; cursor: pointer; padding: 8px;
  & span { display: block; width: 26px; height: 2px; border-radius: 2px; background: #000; }
`;

const Header = ({user, isMobileSideActive, setIsMobileSideActive}) => {

  const is_admin = user?.mem_is_admin == 1;

  return (
    <HeaderWrapStd>
      <HeaderHomeLink to={'/'}>
        <img src={logoImg} alt='' />
      </HeaderHomeLink>
      <HeaderNavStd>
        <Link to={'/reservation'}>예약하기</Link>
        <Link to={'/guide'}>이용안내</Link>
        <Link to={'/price'}>이용요금</Link>
        <Link to={'/review'}>이용후기</Link>
        <Link to={'/faq'}>문의사항</Link>
        <Link to={'/notice'}>공지사항</Link>
      </HeaderNavStd>
      <MemberWrapStd>
        <Link to={'/mypage'}>예약조회</Link>
        {
          is_admin && <a href={'/admin'} target='_blank' rel="noreferrer">관리자</a>
        }
        {
          user ? <Link to={'/logout'}>로그아웃</Link>
            :
            <>
              <Link to={'/login'}>로그인</Link>
              <Link to={'/register'}>회원가입</Link>
            </>
        }
      </MemberWrapStd>
      <MobileSideWrap>
        <MobileButton onClick={() => {setIsMobileSideActive( ! isMobileSideActive)}}>
          <span></span>
          <span></span>
          <span></span>
        </MobileButton>
      </MobileSideWrap>
      <MobileSide
        isMobileSideActive={isMobileSideActive}
        setIsMobileSideActive={setIsMobileSideActive}
        user={user}
        link={[
          {link: '/reservation', name: '예약하기'},
          {link: '/guide', name: '이용안내'},
          {link: '/price', name: '이용요금'},
          {link: '/review', name: '이용후기'},
          {link: '/faq', name: '문의사항'},
          {link: '/notice', name: '공지사항'},
        ]}
      />
    </HeaderWrapStd>
  );
};

export default Header;
