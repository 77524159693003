import './App.css';
import React, {Suspense, useState} from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import LoadingContextComponent from './components/LoadingContextComponent';
import LoadingContext from './Context/LoadingContext';
import { HelmetProvider } from "react-helmet-async"; // 이부분 추가

const AdminRouter = React.lazy(() => import('pages/AdminRouter'));
const MainPage = React.lazy(() => import('pages/MainPage'));
const LoginPage = React.lazy(() => import('pages/auth/LoginPage'));
const RegisterPage = React.lazy(() => import('pages/auth/RegisterPage'));
const LogoutPage = React.lazy(() => import('pages/auth/LogoutPage'));
const GuidePage = React.lazy(() => import('pages/GuidePage'));
const PricePage = React.lazy(() => import('pages/PricePage'));
const FaqPage = React.lazy(() => import('pages/FaqPage'));
const FaqWritePage = React.lazy(() => import('pages/FaqWritePage'));
const FaqModifyPage = React.lazy(() => import('pages/FaqModifyPage'));
const ReservationPage = React.lazy(() => import('pages/ReservationPage'));
const BoardRouter = React.lazy(() => import('pages/BoardRouter'));
const PostPage = React.lazy(() => import('pages/PostPage'));
const MyPage = React.lazy(() => import('pages/MyPage'));
const MyDetailPage = React.lazy(() => import('pages/MyDetailPage'));
const ReservationCompletePage = React.lazy(() => import('pages/ReservationCompletePage'));
const PrivacyPage = React.lazy(() => import('pages/PrivacyPage'));
const TermsPage = React.lazy(() => import('pages/TermsPage'));
// const PrivacySettingPage = React.lazy(() => import('pages/admin'));

function App() {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <HelmetProvider>
        <LoadingContext.Provider value={{setLoading: setLoading, loading: loading}}>
          <Layout>
            <Routes>
              <Route path={'/admin/*'} element={<Suspense><AdminRouter /></Suspense>} />
              <Route path={'/'} element={<Suspense><MainPage /></Suspense>} />
              <Route path={'/login'} element={<Suspense><LoginPage /></Suspense>} />
              <Route path={'/register'} element={<Suspense><RegisterPage /></Suspense>} />
              <Route path={'/logout'} element={<Suspense><LogoutPage /></Suspense>} />
              <Route path={'/guide'} element={<Suspense><GuidePage /></Suspense>} />
              <Route path={'/price'} element={<Suspense><PricePage /></Suspense>} />
              <Route path={'/faq'} element={<Suspense><FaqPage /></Suspense>} />
              <Route path={'/faq/write'} element={<Suspense><FaqWritePage /></Suspense>} />
              <Route path={'/faq/modify'} element={<Suspense><FaqModifyPage /></Suspense>} />
              <Route path={'/faq/:page'} element={<Suspense><FaqPage /></Suspense>} />
              <Route path={'/reservation'} element={<Suspense><ReservationPage /></Suspense>} />
              <Route path={'/reservation/complete'} element={<Suspense><ReservationCompletePage /></Suspense>} />

              <Route path={'/mypage'} element={<Suspense><MyPage /></Suspense>} />
              <Route path={'/mypage/detail'} element={<Suspense><MyDetailPage /></Suspense>} />

              <Route path={'/review/*'} element={<Suspense><BoardRouter boardName={'review'}/></Suspense>} />
              <Route path={'/post/:post_id'} element={<Suspense><PostPage/></Suspense>} />
              <Route path={'/post/*'} element={<Suspense><PostPage/></Suspense>} />
              <Route path={'/notice/*'} element={<Suspense><BoardRouter boardName={'notice'}/></Suspense>} />

              <Route path={'/privacy'} element={<Suspense><PrivacyPage/></Suspense>} />
              <Route path={'/terms'} element={<Suspense><TermsPage/></Suspense>} />
            </Routes>
          </Layout>
          <LoadingContextComponent />
        </LoadingContext.Provider>
      </HelmetProvider>
    </>
  );
}

export default App;


