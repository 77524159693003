import React, {useEffect, useState} from 'react';
import Header from 'components/Header';
import {useSelector} from 'react-redux';
import axios from 'axios';
import Popup from '../components/Popup';
import {useLocation} from 'react-router-dom';

const HeaderContainer = ({}) => {

  const { pathname } = useLocation();
  const { user } = useSelector(({user}) => ({user: user.user}));
  const [isMobileSideActive, setIsMobileSideActive] = useState(false);
  const [popup, setPopup] = useState({});

  useEffect(() => {
    if(pathname === '/') {
      axios.get('/popup/lists', {

      }).then(resp => {
        const {data, status} = resp;
        if(status === 200) {
          setPopup(data);
        }
      }).catch(error => {
      });
    }
  }, []);

  return (
    <>
      <Header
        user={user}
        isMobileSideActive={isMobileSideActive}
        setIsMobileSideActive={setIsMobileSideActive}
      />
      {
        Object.keys(popup).length > 0 &&
        <Popup data={popup}/>
      }
    </>
  );
};

export default HeaderContainer;
