import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

const LoadingStd = styled.div`
  position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, .4); z-index: 99;
  display: flex; align-items: center; justify-content: center;
`

const Loading = ({}) => {
  const {loading} = useSelector(({loading}) => ({loading}));
  return (
    <>
      {
        loading.loading ? <LoadingStd><div className="lds-ripple"><div></div><div></div></div></LoadingStd> : <></>
      }
    </>
  );
};

export default Loading;
