import axios from "axios";

const client = axios.create();

if(process.env.NODE_ENV !== 'development') {
  client.defaults.baseURL = process.env.REACT_APP_API_URL;
}

// client.defaults.headers.common['crossDomain'] = true;
client.defaults.withCredentials = true;
// client.defaults.headers.common['Authorization'] = 'Bearer a1b1';
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  }
)

export default client;
