import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Link, useLocation} from 'react-router-dom';

const MobileSideWrap = styled.div`
  display: block; position: fixed; top: 0; right: 0; left: 0; bottom: 0; z-index: 11;
`
const MobileSideBg = styled.div`
  display: block; position: fixed; top: 0; right: 0; left: 0; bottom: 0; z-index: 12; background: rgba(0, 0, 0, .4);
`
const MobileSideContents = styled.div`
  display: block; position: fixed; top: 0; right: 0; bottom: 0; z-index: 13; background: #222; width: 250px; color: #fff; 
  & ul {}
  & ul li {  }
  & ul li.hr { height: 1px; margin: 8px 16px; background: rgba(255, 255, 255, .2); }
  & ul li a { display: block; line-height: 46px; padding: 0 16px; font-size: 14px; font-weight: 300; }
`
const CloseBtnWrap = styled.div`
  line-height: 60px; display: flex; padding: 8px 16px; justify-content: end;
  & span { display: block; padding: 8px; cursor: pointer; }
  & svg { fill: #fff; display: block; }
`

const MobileSide = ({isMobileSideActive, setIsMobileSideActive, link, user}) => {

  const is_admin = user?.mem_is_admin == 1;

  const { pathname } = useLocation();
  useEffect(() => {
    setIsMobileSideActive(false);
  }, [pathname]);

  if(isMobileSideActive === false) return <></>

  return (
    <MobileSideWrap>
      <MobileSideContents>
        <CloseBtnWrap>
          <span onClick={() => setIsMobileSideActive(false)}>
            <svg height="20" viewBox="0 0 512 512" width="20" xmlns="http://www.w3.org/2000/svg"><g id="_02_User" data-name="02 User"><path d="m25 512a25 25 0 0 1 -17.68-42.68l462-462a25 25 0 0 1 35.36 35.36l-462 462a24.93 24.93 0 0 1 -17.68 7.32z"></path><path d="m487 512a24.93 24.93 0 0 1 -17.68-7.32l-462-462a25 25 0 0 1 35.36-35.36l462 462a25 25 0 0 1 -17.68 42.68z"></path></g></svg>
          </span>
        </CloseBtnWrap>
        <ul>
          {link.length > 0 && link.map((o, i) => {
            return <li key={i}>
              <Link to={o.link}>{o.name}</Link>
            </li>
          })}
          <li className={'hr'}></li>
          <li><Link to={'/mypage'}>예약조회</Link></li>
          {
            is_admin && <li><a href={'/admin'} target='_blank' rel="noreferrer">관리자</a></li>
          }
          {
            user ? <li><Link to={'/logout'}>로그아웃</Link></li>
              :
              <>
                <li><Link to={'/login'}>로그인</Link></li>
                <li><Link to={'/register'}>회원가입</Link></li>
              </>
          }
        </ul>
      </MobileSideContents>
      <MobileSideBg
        onClick={() => setIsMobileSideActive(false)}
      />
    </MobileSideWrap>
  );
};

export default MobileSide;
