import React, {useContext} from 'react';
import LoadingContext from '../Context/LoadingContext';
import styled from 'styled-components';

const LoadingStd = styled.div`
  position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, .4); z-index: 99;
  display: flex; align-items: center; justify-content: center;
`

const LoadingContextComponent = ({}) => {
  const context = useContext(LoadingContext);
  return (
    <>
      {
        context.loading ? <LoadingStd><div className="lds-ripple"><div></div><div></div></div></LoadingStd> : <></>
      }
    </>
  );
};

export default LoadingContextComponent;
