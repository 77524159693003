import React from 'react';
import styled from 'styled-components';

const ContainerStd = styled.div`
  max-width: ${props => props.$width || '1168' }px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
`

const Container = ({children, $width}) => {
  return (
    <ContainerStd $width={$width}>
      {children}
    </ContainerStd>
  );
};

export default Container;
