import client from './client';

export const login = ({userId, password}) =>
  client.post('/login', {userId, password});
export const register = ({userId, userName, password, nickname, email}) =>
  client.post('/register', {userId, userName, password, nickname, email});

export const check = () => client.get('/check');

export const logout = () => client.post('/logout');
