import {createAction, handleActions} from 'redux-actions';
import {takeLatest, call} from 'redux-saga/effects';
import * as authAPI from 'lib/api/auth';
import createRequestSaga, {createRequestActionTypes} from '../lib/createRequestSaga';

const TEMP_SET_USER = 'user/TEMP_SET_USER';
const[CHECK, CHECK_SUCCESS, CHECK_FAILURE] = createRequestActionTypes('user/CHECK');
const LOGOUT = 'user/LOGOUT';

export const tempSetUser = createAction(TEMP_SET_USER, user => user);
export const check = createAction(CHECK);
export const logout = createAction(LOGOUT);

const checkSaga = createRequestSaga(CHECK, authAPI.check);
export function * userSaga() {
  yield takeLatest(CHECK, checkSaga);
  yield takeLatest(LOGOUT, logoutSaga);
}

const initialState = {
  user: null,
  checkError: null,
}

function * logoutSaga() {
  try {
    yield call(authAPI.logout);
  } catch (e) {
    console.log(e);
  }
}

export default handleActions(
  {
    [TEMP_SET_USER]: (state, {payload: user}) => ({
      ...state,
      user,
    }),
    [CHECK_SUCCESS]: (state, {payload: user}) => {
      return ({
        ...state,
        user: {
          'mem_id': user.mem_id,
          'mem_userid': user.mem_userid,
          'mem_is_admin': user.mem_is_admin,
          'mem_username': user.mem_username,
        },
        checkError: null,
      })
    },
    [CHECK_FAILURE]: (state, {payload: error}) =>({
      ...state,
      user: null,
      checkError: error,
    }),
    [LOGOUT]: (state, {payload: error}) => {
      return ({
        ...state,
        user: null
      });
    },

  },
  initialState
);

