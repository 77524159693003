import React, {useState} from 'react';
import styled from 'styled-components';
import PopupEachComponent from './PopupEachComponent';

const Wrap = styled.div`
  position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 110;
`


const Popup = ({data}) => {

  /*const on24Close = () => {
    const today = new Date();
    const expires = new Date();
    expires.setDate(today.getDate() + 1);

    setCookie(`popup_${data.pop_id}`, 1, {
      path: '/',
      secure: '/',
      expires: expires
    });
    setIsActive(false);
  }*/

  return (
    <>
      {data.length > 0 && <>
        {
          data.map((d, i) => {
            return <PopupEachComponent key={d.pop_id} data={d} index={i}/>
          })
        }
      </>}
    </>
  );
};

export default Popup;
