import {createAction, handleActions} from 'redux-actions';
import {produce} from 'immer';
import {takeLatest} from 'redux-saga/effects';
import createRequestSaga, {
  createRequestActionTypes
} from '../lib/createRequestSaga';
import * as authApi from 'lib/api/auth';

const CHANGE_FIELD = 'auth/CHANGE_FIELD';
const INITIALIZE_FORM = 'auth/INITIALIZE_FORM';
// const LOGOUT = 'auth/LOGOUT';

const [REGISTER, REGISTER_SUCCESS, REGISTER_FAILURE] = createRequestActionTypes('auth/REGISTER');
const [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE] = createRequestActionTypes('auth/LOGIN');

export const changeField = createAction(
  CHANGE_FIELD,
  ({form, key, value}) => ({
    form,
    key,
    value,
  })
);

export const initializeForm = createAction(INITIALIZE_FORM, form => form);


export const register = createAction(REGISTER, ({userId, userName, password, nickname, email}) => ({userId, userName, password, nickname, email}));
export const login = createAction(LOGIN, ({userId, password}) => ({
  userId,
  password
}));

const loginSaga = createRequestSaga(LOGIN, authApi.login);
const registerSaga = createRequestSaga(REGISTER, authApi.register);

export function* authSaga() {
  yield takeLatest(LOGIN, loginSaga);
  yield takeLatest(REGISTER, registerSaga);
}

const initialState = {};

const auth = handleActions(
  {
    [CHANGE_FIELD]: (state, {payload: {form, key, value}}) =>
      produce(state, draft => {
        draft[form][key] = value;
    }),
    [INITIALIZE_FORM]: (state, {payload: form}) => ({
      ...state,
      [form]: initialState[form]
    }),
    [LOGIN_SUCCESS]: (state, {payload: auth}) => ({
      ...state,
      authError: null,
      auth,
    }),
    [LOGIN_FAILURE]: (state, {payload: error}) => ({
      ...state,
      authError: error,
    }),
    [REGISTER_SUCCESS]: (state, {payload: auth}) => ({
      ...state,
      authError: null,
      auth
    }),
    [REGISTER_FAILURE]: (state, {payload: error}) => {
      return ({
        ...state,
        authError: error,
      });
    },
  },
  initialState
);

export default auth;
