import React, {Suspense} from 'react';
import Footer from './Footer';
import HeaderContainer from '../containers/HeaderContainer';
import {Route, Routes} from 'react-router-dom';

const AdminHeaderContainer = React.lazy(() => import('containers/admin/AdminHeaderContainer'));

const Layout = ({children}) => {
  return (
    <>
      <Routes>
        <Route path={'/admin/*'} element={<Suspense><AdminHeaderContainer /></Suspense>} />
        <Route path={'*'} element={<HeaderContainer />} />
      </Routes>

      {children}

      <Routes>
        <Route path={'/admin/*'} element={<></>} />
        <Route path={'*'} element={<Footer />} />
      </Routes>
    </>
  );
};

export default Layout;
