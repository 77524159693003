import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Container from './Container';
import {Link} from 'react-router-dom';
import footerIcon01 from 'img/footer-img01.png';
import footerIcon02 from 'img/footer-img02.png';
import footerIcon03 from 'img/footer-img03.png';
import footerIcon04 from 'img/footer-img04.png';
import axios from 'axios';
import Kakao from 'img/kakao.png';

const FooterIconStd = styled.div`
  display: flex; gap: 13px; margin-bottom: 16px;
`
const FooterLinkStd = styled.div`
  display: flex; gap: 16px;
`
const FooterArticleStd = styled.div`
  display: flex; 
  flex-direction: column;
  justify-content: center;
  & + & { align-items: end; }
  & h5 { font-size: 16px; font-weight: 400; margin-bottom: 16px; }
`
const FooterContentsCopyTextStd = styled.div`
  font-size: 11px; font-weight: 400; opacity: .6; margin-top: 16px;
`;
const FooterContentsTextStd = styled.div`
  font-size: 13px;
  & p { display: flex; gap: 16px; }
  @media screen and (max-width: 768px) {
    & p { flex-direction: column; gap: 2px; font-size: 12px; }    
  }
`
const FooterContentsStd = styled.div`
  display: flex; justify-content: space-between; flex-direction: row;
  @media screen and (max-width: 768px) {
    flex-direction: column; padding: 0 16px; align-items: center; justify-content: center; gap: 40px; text-align: center;
  }
`
const FooterStd = styled.div`
  background: #3B486B;
  color: #fff;
  padding: 58px 0;
`
const KakaoLink = styled.a`
  position: fixed; bottom: 30px; right: 30px; color: #000; text-align: center; line-height: 1.5;
  z-index: 1;
  width: 120px; background: #fee710; height: 120px; border-radius: 100%; display: flex; align-items: center; justify-content: center; 
  & img { width: 40px; }
  & h5 { font-size: 14px; font-weight: 700; }
  & p { font-size: 12px; margin: 0 0 12px; }
  & > div { display: flex; flex-direction: column; align-items: center; }
  &:hover { color: #000; }
  @media screen and (max-width: 768px) {
    & { width: 100px; height: 100px; }
    & img { width: 30px; }
    & h5 { font-size: 12px; font-weight: 700; }
    & p { font-size: 10px; margin: 0 0 6px; }
  }
`

const Footer = ({}) => {

  const [blogLink, setBlogLink] = useState('');
  const [youtubeLink, setYoutubeLink] = useState('');
  const [instaLink, setInstaLink] = useState('');
  const [facebookLink, setFacebookLink] = useState('');

  const [companyName, setCompanyName] = useState('');
  const [ceo, setCeo] = useState('');
  const [mail, setMail] = useState('');
  const [businessNo, setBusinessNo] = useState('');
  const [tel, setTel] = useState('');
  const [address, setAddress] = useState('');
  const [salesNo, setSalesNo] = useState('');

  useEffect(() => {
    axios.get(
      `/getSnsLink`
    ).then(({data}) => {
      const {blogLink, facebookLink, instaLink, youtubeLink} = data;
      setBlogLink(blogLink);
      setFacebookLink(facebookLink);
      setInstaLink(instaLink);
      setYoutubeLink(youtubeLink);
    }).catch(err => {

    });
    axios.get(
      `/getFooter`
    ).then(({data}) => {
      const {companyName, ceo, mail, businessNo, tel, address, salesNo} = data;
      setCompanyName(companyName);
      setCeo(ceo);
      setMail(mail);
      setBusinessNo(businessNo);
      setTel(tel);
      setAddress(address);
      setSalesNo(salesNo);
    }).catch(err => {

    });



  }, []);


  return (
    <FooterStd>
      <Container>
        <FooterContentsStd>
          <FooterArticleStd>
            <h5>GOBAG</h5>
            <FooterContentsTextStd>
              <p>
                <span>상호 : {companyName}</span>
                <span>대표자 : {ceo}</span>
                <span>대표메일 : {mail}</span>
                <span>사업자등록번호 : {businessNo}</span>
                <span>대표연락처 : {tel}</span>
              </p>
              <p>
                <span>소재지 : {address}</span>
                <span>통신판매: {salesNo}</span>
              </p>
            </FooterContentsTextStd>
            <FooterContentsCopyTextStd>Copyright © 2023 이든 Corporation.</FooterContentsCopyTextStd>
          </FooterArticleStd>
          <FooterArticleStd>
            <FooterIconStd>
              <a href={blogLink} target='_blank'><img src={footerIcon01} /></a>
              <a href={youtubeLink} target='_blank'><img src={footerIcon02} /></a>
              <a href={instaLink} target='_blank'><img src={footerIcon03} /></a>
              <a href={facebookLink} target='_blank'><img src={footerIcon04} /></a>
            </FooterIconStd>
            <FooterLinkStd>
              <Link to={'/privacy'}>개인정보처리방침</Link>
              <Link to={'/terms'}>이용약관</Link>
            </FooterLinkStd>
          </FooterArticleStd>
        </FooterContentsStd>
      </Container>
      <KakaoLink href='http://pf.kakao.com/_KWtlG/chat' target={'_blank'}>
        <div>
          <img src={Kakao} alt='' />
          <h5>상담하기</h5>
          <p>08:00~19:00</p>
        </div>
      </KakaoLink>
    </FooterStd>
  );
};

export default Footer;
