import React, {useState} from 'react';
import {getCookie} from '../lib/Cookie';
import styled from 'styled-components';

const Wrap = styled.div`
  position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 110;
`
const Contents = styled.div`
  position: absolute; max-width: 430px; max-height: 600px; left: 50%; top: 50%; transform: translateX(-50%) translateY(-50%); z-index: 12; padding: 15px; width: 100%;
  & img { display: block; width: 100%; }
  @media screen and (max-width: 768px) {
    margin: 0 !important;
  }
`
const ImgWrap = styled.div``
const Close = styled.div`display: flex; justify-content: end; color: #fff; font-size: 16px; font-weight: 500; margin: 0 0 8px;
  gap: 16px;
  & div { cursor: pointer; }
`
const Bg = styled.div`
  position: absolute; left: 0; right: 0; top: 0; bottom: 0; background: rgba(0, 0, 0, .4); z-index: 11;
`

const PopupEachComponent = ({data, index}) => {

  const [isActive, setIsActive] = useState(true);
  const file = data?.pop_file[0]?.pfi_filename;

  if( ! file) return;

  if(parseInt(getCookie(`popup_${data.pop_id}`)) === 1) {
    return <></>
  }

  const onClose = () => {
    setIsActive(false);
  }

  return (
    isActive === true && <>
      <Wrap>
        <Contents style={{marginTop: 30 * index, marginLeft: 30 * index}}>
          <Close>
            <div onClick={() => onClose()}>닫기</div>
          </Close>
          <ImgWrap>
            <img src={`https://www.edengobag.com/uploads/popup/${file}`} alt={'팝업'}/>
          </ImgWrap>
        </Contents>
        {
          index === 0 && <Bg />
        }
      </Wrap>
    </>
  );
};

export default PopupEachComponent;
